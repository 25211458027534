import React, { useEffect, useState } from "react";
import { Link, Route, Routes, useNavigate, useParams } from "react-router-dom";

import View from "./View";
import Document from "./Document";
import api from "../../../services/api";
import toast from "react-hot-toast";
import Loader from "../../../components/Loader";
import { FiExternalLink } from "react-icons/fi";
import { APP_URL, TENANT_URL } from "../../../services/config";
import DocumentsDetails from "./DocumentsDetails";
import { FaTrash, FaCloudDownloadAlt, FaCheck } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";

import Scoring from "./Scoring";
import { FaEye, FaXmark } from "react-icons/fa6";
import Select from "../../../components/Select";

const Index = () => {
  const { id } = useParams();
  const [report, setReport] = useState({});
  const [values, setValues] = useState({});
  const [coupleData, setCoupleData] = useState({});
  const [tab, setTab] = useState("tenant");
  const [loading, setLoading] = useState(false);
  const [printing, setPrinting] = useState(false);
  const [sending, setSending] = useState(false);
  const activeLink = location.pathname.split(id).pop();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await api.get(`/report/${id}`);
        if (!res.ok) throw res;
        // if (res.data.situation === "COUPLE") {
        //   const coupleRes = await api.get(`/report/${res.data.couple_report_id}`);
        //   if (!coupleRes.ok) throw coupleRes;
        //   setCoupleData(coupleRes.data);
        // }
        setReport(res.data);
      } catch (error) {
        console.error(error);
        toast.error("Une erreur est survenue");
      }
      setLoading(false);
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    if (!values._id) return setValues({ ...report });
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await api.get(`/report/${tab === "tenant" ? report._id : report.couple_report_id}`);
        if (!res.ok) throw res;
        setValues(res.data);
      } catch (error) {
        console.error(error);
        toast.error("Une erreur est survenue");
      }
      setLoading(false);
    };
    fetchData();
  }, [tab, report]);

  const handleChange = async () => {
    try {
      const res = await api.put(`/report/${values._id}`, values);
      if (!res.ok) throw res;
      setReport(res.data);
      if (tab === "tenant") setValues({ ...res.data });

      // if (tenantTab) {
      //   const res = await api.put(`/report/${report._id}`, report);
      //   if (!res.ok) throw res;
      //   setReport(res.data);
      // } else {
      //   const coupleRes = await api.put(`/report/${coupleData._id}`, coupleData);
      //   if (!coupleRes.ok) throw coupleRes;
      //   setCoupleData(coupleRes.data);
      // }
      toast.success("Dossier mis à jour !");
    } catch (error) {
      toast.error("une erreur est survenu");
    }
  };

  const handleChangeStatus = async (value) => {
    try {
      const res = await api.put(`/report/${report._id}`, { status: value });
      if (!res.ok) throw res;
      setReport(res.data);
      if (tab === "tenant") setValues({ ...res.data });
      toast.success("Dossier mis à jour !");
    } catch (error) {
      toast.error("une erreur est survenu");
    }
  };

  const handleDownload = async () => {
    setPrinting(true);
    try {
      const response = await api.pdf(`/report/${report._id}/pdf`);
      const blob = new Blob([response], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `rapport-dossier-${report.user_first_name}-${report.user_last_name}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error(error);
    }
    setPrinting(false);
  };

  const handleSendTenantEmail = async () => {
    setSending(true);
    try {
      const res = await api.post(`/report/${report._id}/send-to-tenant`);
      if (!res.ok) throw res;
      toast.success("Email envoyé à l'agence !");
      setReport(res.data);
    } catch (error) {
      toast.error("une erreur est survenu");
    }
    setSending(false);
  };

  const handleSendAgencyEmail = async () => {
    setSending(true);
    try {
      const res = await api.post(`/report/${report._id}/send-to-agency`);
      if (!res.ok) throw res;
      toast.success("Email envoyé à l'agence !");
      setReport(res.data);
    } catch (error) {
      toast.error("une erreur est survenu");
    }
    setSending(false);
  };

  const handleDelete = async () => {
    try {
      const res = await api.delete(`/report/${report._id}`);
      if (!res.ok) throw res;
      toast.success("Le dossier a été supprimé");
      navigate("/report");
    } catch (error) {
      toast.error("une erreur est survenu");
    }
  };

  if (!report._id) return <Loader />;

  return (
    <div className="p-8">
      <div className="w-full flex justify-end items-center mb-8">
        <div className="flex items-start gap-4">
          <a href={`${TENANT_URL}/?token=${report.token}`} className="primary-button">
            <FiExternalLink className="mr-2" /> Accéder au dossier
          </a>
          <button onClick={handleDownload} className="primary-button">
            <FaCloudDownloadAlt className="mr-2" /> {printing ? "Chargement..." : "Générer le rapport"}
          </button>
          {report.report_url && (
            <a href={report.report_url} target="_blank" rel="noreferrer" className="primary-button">
              <FaEye />
            </a>
          )}
          <div className="space-y-2">
            <button onClick={handleSendTenantEmail} className="primary-button" disabled={!report.report_url || sending}>
              <MdOutlineEmail className="mr-2" /> {sending ? "Chargement..." : "Envoyer par email au locataire"}
            </button>
            {report.sent_to_tenant_at && <span className="text-xs text-gray-500">Envoyé le {new Date(report.sent_to_tenant_at).toLocaleDateString("fr-FR")}</span>}
          </div>
          <div className="space-y-2">
            <button onClick={handleSendAgencyEmail} className="primary-button" disabled={!report.report_url || sending}>
              <MdOutlineEmail className="mr-2" /> {sending ? "Chargement..." : "Envoyer par email à l'agence"}
            </button>
            {report.sent_to_agency_at && <span className="text-xs text-gray-500">Envoyé le {new Date(report.sent_to_agency_at).toLocaleDateString("fr-FR")}</span>}
          </div>
          <button onClick={handleDelete} className="red-button">
            <FaTrash className="mr-2" /> Supprimer
          </button>
        </div>
      </div>
      <div className="flex justify-between items-center mb-4 gap-8">
        <h1 className="text-2xl font-bold ml-5">
          Dossier de {report.user_first_name} {report.user_last_name} créé le {new Date(report.created_at).toLocaleDateString("fr-FR")}
        </h1>
        <div className="flex items-center gap-4">
          <span
            className={`inline-flex items-center gap-x-1.5 rounded-md ${report.has_guarantor ? "bg-green-100" : "bg-red-100"} px-2 py-1 text-xs font-medium ${
              report.has_guarantor ? "text-green-600" : "text-red-600"
            }`}>
            {report.has_guarantor ? <FaCheck /> : <FaXmark />}A un garant
          </span>
          <span
            className={`inline-flex items-center gap-x-1.5 rounded-md ${report.situation === "COUPLE" ? "bg-green-100" : "bg-red-100"} px-2 py-1 text-xs font-medium ${
              report.situation === "COUPLE" ? "text-green-600" : "text-red-600"
            }`}>
            {report.situation === "COUPLE" ? <FaCheck /> : <FaXmark />}A un.e conjoint.e
          </span>
        </div>
        <div className="w-40">
          <Select
            options={["INCOMPLETED", "COMPLETED", "PROCESSING"]}
            value={["FINALIZED", "VERIFIED"].includes(report.status) ? "COMPLETED" : report.status}
            onChange={(value) => handleChangeStatus(value)}
            getLabel={(status) => STATUS_SPAN[status]}
            position="left-0 bottom-full w-24"
          />
        </div>
      </div>
      {report.situation === "COUPLE" && (
        <div className="grid grid-cols-2 mb-2 relative">
          <button
            className={`flex items-center justify-center px-4 py-2 cursor-pointer relative transition-all duration-300 ${tab === "tenant" ? "text-primary" : "text-gray-500"}`}
            onClick={() => setTab("tenant")}>
            Dossier du locataire
          </button>
          <button
            className={`flex items-center justify-center px-4 py-2 cursor-pointer relative transition-all duration-300 ${tab === "spouse" ? "text-primary" : "text-gray-500"}`}
            onClick={() => setTab("spouse")}>
            Dossier du/de la conjoint.e
          </button>
          <div
            className={`absolute bottom-0 left-0 h-1 bg-primary transition-all duration-300 ${tab === "tenant" ? "translate-x-0" : "translate-x-full"}`}
            style={{ width: "50%", transform: `translateX(${tab === "tenant" ? "0%" : "100%"})` }}
          />
        </div>
      )}
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="flex gap-4">
            <Link to="" className={`rounded-lg px-4 py-2 ${activeLink === "" ? "bg-gray-100" : "border border-secondary"}`}>
              Infos
            </Link>
            <Link to="report-document" className={`rounded-lg px-4 py-2 ${activeLink === "/report-document" ? "bg-gray-100" : "border border-secondary"}`}>
              Documents associés
            </Link>
            <Link to="report-documents-details" className={`rounded-lg px-4 py-2 ${activeLink === "/report-documents-details" ? "bg-gray-100" : "border border-secondary"}`}>
              Détails des documents
            </Link>
            <Link to="scoring" className={`rounded-lg px-4 py-2 ${activeLink === "/scoring" ? "bg-gray-100" : "border border-secondary"}`}>
              Scoring
            </Link>
            <Link to="raw" className={`rounded-lg px-4 py-2 ${activeLink === "/raw" ? "bg-gray-100" : "border border-secondary"}`}>
              Données brutes
            </Link>
          </div>
          <section className="mt-8">
            <Routes>
              <Route path="" element={<View data={values} />} />
              <Route path="/report-document" element={<Document report={values} onChange={handleChange} />} />
              <Route path="/report-documents-details" element={<DocumentsDetails report={values} onChange={setValues} />} />
              <Route path="/scoring" element={<Scoring report={values} onChange={handleChange} />} />
              <Route path="/raw" element={<Raw data={values} />} />
            </Routes>
            {/* <Routes>
          <Route path="" element={<View data={tenantTab ? report : coupleData} />} />
          <Route path="/report-document" element={<Document report={tenantTab ? report : coupleData} onChange={handleChange} />} />
          <Route
            path="/report-documents-details"
            element={
              <DocumentsDetails
                report={tenantTab ? report : coupleData}
                otherReport={tenantTab ? coupleData : report}
                setReport={tenantTab ? setReport : setCoupleData}
                setOtherReport={tenantTab ? setCoupleData : setReport}
              />
            }
          />
          <Route path="/scoring" element={<Scoring report={tenantTab ? report : coupleData} onChange={handleChange} />} />
          <Route path="/raw" element={<Raw data={tenantTab ? report : coupleData} />} />
        </Routes> */}
          </section>
        </>
      )}
    </div>
  );
};

const STATUS_SPAN = {
  INCOMPLETED: (
    <span className="inline-flex items-center gap-1.5  rounded-md bg-orange-100 px-3 py-1 text-xs font-medium text-orange-600 border border-orange-600">
      <span className={`h-2 w-2 rounded-full bg-orange-400`} /> Incomplet
    </span>
  ),
  COMPLETED: (
    <span className="inline-flex items-center gap-1.5 rounded-md bg-cyan-100 px-3 py-1 text-xs font-medium text-cyan-600 border border-cyan-600">
      <span className={`h-2 w-2 rounded-full bg-cyan-400`} />
      Complet
    </span>
  ),
  PROCESSING: (
    <span className="inline-flex items-center gap-1.5 rounded-md bg-purple-100 px-3 py-1 text-xs font-medium text-purple-600 border border-purple-600">
      <span className={`h-2 w-2 rounded-full bg-purple-400`} />
      En traitement
    </span>
  ),
  VERIFIED: (
    <span className="inline-flex items-center gap-1.5 rounded-md bg-green-100 px-3 py-1 text-xs font-medium text-green-600 border border-green-600">
      <span className={`h-2 w-2 rounded-full bg-green-400`} />
      Traité
    </span>
  ),
  FINALIZED: (
    <span className="inline-flex items-center gap-1.5 rounded-md bg-blue-100 px-3 py-1 text-xs font-medium text-blue-600 border border-blue-600">
      <span className={`h-2 w-2 rounded-full bg-blue-400`} />
      Finalisé
    </span>
  ),
  SENT_TO_AGENCY: (
    <span className="inline-flex items-center gap-1.5 rounded-md bg-pink-100 px-3 py-1 text-xs font-medium text-pink-600 border border-pink-600">
      <span className={`h-2 w-2 rounded-full bg-pink-400`} />
      Envoyé à l'agence
    </span>
  ),
};

const Raw = ({ data }) => (
  <div className="col-span-2 bg-gray-900 rounded-lg p-5 flex flex-col gap-2">
    <pre className="text-white text-sm">{JSON.stringify(data, null, 2)}</pre>
  </div>
);

export default Index;
