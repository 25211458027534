import { useEffect, useState } from "react";
import toast from "react-hot-toast";

import api from "../../../services/api";
import Loader from "../../../components/Loader";
import { FaCheck } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";

const Scoring = ({ report }) => {
  const [data, setData] = useState({
    scoring_rental_capacity: 0,
    scoring_employment_contract_type: 0,
    scoring_employment_seniority: 0,
    scoring_rent_receipt: 0,
    scoring_tenant_age: 0,
    scoring_document_fraud: 0,
    scoring_identity_fraud: 0,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!report._id) return;
    fetchData();
  }, [report]);

  const fetchData = async () => {
    setLoading(true);
    if (!report.scoring_id || (report.has_guarantor && !report.guarantor_scoring_id)) return setLoading(false);
    try {
      const res = await api.get(`/scoring/${report.has_guarantor ? report.guarantor_scoring_id : report.scoring_id}`);
      if (res.ok) {
        setData(res.data);
      }
    } catch (err) {
      console.error(err);
      toast.error("Une erreur est survenue");
    }
    setLoading(false);
  };

  if (loading) return <Loader />;

  if (!report.scoring_id || (report.has_guarantor && !report.guarantor_scoring_id)) return <div className="text-center">Aucune donnée de scoring disponible</div>;

  return (
    <>
      <div className="space-y-4 py-8">
        <div className="space-y-2">
          <div className="border border-secondary rounded-lg p-5 grid grid-cols-4 gap-4">
            <div className="space-y-2 flex flex-col justify-center items-center">
              <label className="text-sm font-semibold">Taux d'effort</label>
              {data?.effort_rate ? data.effort_rate.toLocaleString("fr-FR", { style: "percent" }) : "-"}
            </div>
            <div className="space-y-2 flex flex-col justify-center items-center">
              <label className="text-sm font-semibold">Type de contrat</label>
              {data?.payslip_contract_type}
            </div>
            <div className="space-y-2 flex flex-col justify-center items-center">
              <label className="text-sm font-semibold">Ancienneté</label>
              {data?.payslip_contract_duration > 1 ? `${data.payslip_contract_duration} ans` : `${data.payslip_contract_duration} an`}
            </div>
            <div className="space-y-2 flex flex-col justify-center items-center">
              <label className="text-sm font-semibold">Quittance de loyer</label>
              {data?.rent_receipt_amount ? data.rent_receipt_amount.toLocaleString("fr-FR", { style: "currency", currency: "EUR" }) : "-"}
            </div>
            <div className="space-y-2 flex flex-col justify-center items-center">
              <label className="text-sm font-semibold">Âge du locataire</label>
              {data?.cni_age} ans
            </div>
            <div className="space-y-2 flex flex-col justify-center items-center">
              <label className="text-sm font-semibold">Fraude documentaire</label>
              <div className="flex items-center gap-2">
                <div className="flex-1 text-xs text-center flex flex-col items-center">
                  <span>CNI</span>
                  {data.cni_statut === "00" ? <FaCheck className="text-green-500" /> : <FaXmark className="text-red-500" />}
                </div>
                <div className="flex-1 text-xs text-center flex flex-col items-center">
                  <span>Fiche de paie</span>
                  {data.payslip_statut === "00" ? <FaCheck className="text-green-500" /> : <FaXmark className="text-red-500" />}
                </div>
                <div className="flex-1 text-xs text-center flex flex-col items-center">
                  <span>Avis d'imposition</span>
                  {data.tax_notice_statut === "00" ? <FaCheck className="text-green-500" /> : <FaXmark className="text-red-500" />}
                </div>
                <div className="flex-1 text-xs text-center flex flex-col items-center">
                  <span>Quittance de loyer</span>
                  {data.rent_receipt_statut === "00" ? <FaCheck className="text-green-500" /> : <FaXmark className="text-red-500" />}
                </div>
              </div>
            </div>
            <div className="space-y-2 flex flex-col justify-center items-center">
              <label className="text-sm font-semibold">Taux de confiance carte d'identité</label>
              {data?.identity_fraud ? `${data?.identity_fraud} %` : "-"}
            </div>
          </div>
          <div className="space-y-2 flex flex-col justify-center items-center">
            <label className="text-sm font-semibold">Score final du dossier</label>
            <CircleScore score={data?.final_score} />
          </div>
          <div className="border border-secondary rounded-lg p-5 grid grid-cols-4 gap-4">
            <div className="space-y-2 flex flex-col justify-center items-center">
              <label className="text-sm font-semibold">Score de capacité locative</label>
              {data?.scoring_rental_capacity}
            </div>
            <div className="space-y-2 flex flex-col justify-center items-center">
              <label className="text-sm font-semibold">Score de type de contrat de travail</label>
              {data?.scoring_employment_contract_type}
            </div>
            <div className="space-y-2 flex flex-col justify-center items-center">
              <label className="text-sm font-semibold">Score de durée du contrat de travail</label>
              {data?.scoring_employment_seniority}
            </div>
            <div className="space-y-2 flex flex-col justify-center items-center">
              <label className="text-sm font-semibold">Score de quittance de loyer</label>
              {data?.scoring_rent_receipt}
            </div>
            <div className="space-y-2 flex flex-col justify-center items-center">
              <label className="text-sm font-semibold">Score d'âge du locataire</label>
              {data?.scoring_tenant_age}
            </div>
            <div className="space-y-2 flex flex-col justify-center items-center">
              <label className="text-sm font-semibold">Score de fraude documentaire</label>
              {data?.scoring_document_fraud}
            </div>
            <div className="space-y-2 flex flex-col justify-center items-center">
              <label className="text-sm font-semibold">Score de fraude d'identité</label>
              {data?.scoring_identity_fraud}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const CircleScore = ({ score, maxScore = 100 }) => {
  const radius = 30;
  const circumference = 2 * Math.PI * radius;
  const percentage = score / maxScore;
  const strokeDashoffset = circumference * (1 - percentage);

  return (
    <div className="relative flex items-center justify-center w-44 h-44">
      <svg className="rotate-[-90deg]" width="100%" height="100%" viewBox="0 0 100 100">
        <circle cx="50" cy="50" r={radius} fill="transparent" stroke="#D9D9D9" strokeWidth="5" />
        <circle
          cx="50"
          cy="50"
          r={radius}
          fill="transparent"
          stroke="#4CAF50"
          strokeWidth="5"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap="round"
        />
      </svg>
      <div className="absolute">
        <span className="text-3xl font-semibold">{`${Math.round(score)}%`}</span>
      </div>
    </div>
  );
};

export default Scoring;
