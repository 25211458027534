import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

import api from "../../services/api";
import { API_URL } from "../../services/config";
import Loader from "../../components/Loader";
import Pagination from "../../components/Pagination";
import TablePagination from "../../components/TablePagination";

const HEADER = [{ title: "Nom" }, { title: "E-mail" }, { title: "Inscrit le" }, { title: "Dernière connexion" }];

const List = () => {
  const [filters, setFilters] = useState({
    page: 1,
    limit: 50,
    search: "",
  });

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const res = await api.post(`/admin/user/search`, filters);
        if (!res.ok) throw res;
        setData(res.data);
        setTotal(res.total);
      } catch (error) {
        console.error(error);
        toast.error("Une erreur est survenue");
      }
      setLoading(false);
    };
    fetchData();
  }, [filters]);

  return (
    <div className="p-8 space-y-8">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Utilisateurs</h1>

        <Link to="/user/new" className="primary-button">
          Ajouter un utilisateur
        </Link>
      </div>
      <div className="flex justify-between items-center">
        <input type="text" placeholder="Rechercher" className="input w-96" value={filters.search} onChange={(e) => setFilters({ ...filters, search: e.target.value })} />
      </div>

      <TablePagination header={HEADER} loading={loading} page={filters.page} total={total} pageSize={filters.limit} onPageChange={(page) => setFilters({ ...filters, page })}>
        {data.map((item, index) => (
          <tr key={index} className={`cursor-pointer ${index % 2 === 0 ? "bg-neutral-50" : "bg-white"} hover:bg-secondary`} onClick={() => navigate(`/email/${item._id}`)}>
            <td className="p-4">
              {item.first_name} {item.last_name}
            </td>
            <td className="px-4">{item.email}</td>
            <td className="px-4">{new Date(item.created_at).toLocaleDateString("fr-FR")}</td>
            <td className="px-4">{item.last_login_at ? new Date(item.last_login_at).toLocaleDateString("fr-FR") : "-"}</td>
          </tr>
        ))}
      </TablePagination>
    </div>
  );
};

export default List;
