import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import api from "../../services/api";
import Loader from "../../components/Loader";
import Pagination from "../../components/Pagination";
import Table from "../../components/Table";
import TablePagination from "../../components/TablePagination";

const HEADER = [{ title: "Expéditeur" }, { title: "Objet" }, { title: "Destinataire" }, { title: "Date" }];

const List = () => {
  const [filters, setFilters] = useState({
    page: 1,
    limit: 50,
    search: "",
  });

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetcgData = async () => {
      setLoading(true);
      try {
        const res = await api.post("/email/search", filters);
        if (!res.ok) throw res;
        setData(res.data);
        setTotal(res.total);
      } catch (err) {
        console.error(err);
        toast.error("Une erreur est survenue");
      }
      setLoading(false);
    };
    fetcgData();
  }, [filters]);

  return (
    <div className="p-8 space-y-8">
      <h1 className="text-2xl font-bold">Emails</h1>
      <div className="flex justify-between items-center">
        <input type="text" placeholder="Rechercher" className="input w-96" value={filters.search} onChange={(e) => setFilters({ ...filters, search: e.target.value })} />
      </div>

      <TablePagination header={HEADER} loading={loading} page={filters.page} total={total} pageSize={filters.limit} onPageChange={(page) => setFilters({ ...filters, page })}>
        {data.map((item, index) => (
          <tr key={index} className={`cursor-pointer ${index % 2 === 0 ? "bg-neutral-50" : "bg-white"} hover:bg-secondary`} onClick={() => navigate(`/email/${item._id}`)}>
            <td className="p-4">{item.from_email}</td>
            <td className="px-4">{item.subject}</td>
            <td className="px-4">{item.to.map((e) => e.email).join(", ")}</td>
            <td className="px-4">{new Date(item.sent_at).toLocaleDateString("fr-FR")}</td>
          </tr>
        ))}
      </TablePagination>
    </div>
  );
};

export default List;
