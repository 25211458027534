import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Loader from "./Loader";

const Table = ({ header, sort, total, onSort, loading, children, fixed = false, sticky = false, height = "h-full" }) => {
  return (
    <div className={`w-full border rounded overflow-x-auto ${height}`}>
      <table className={`w-full ${fixed ? "table-fixed" : ""}`}>
        <thead className={`text-left ${sticky ? "sticky top-0 z-10 bg-white shadow-md" : ""}`}>
          <tr className="border-b">
            {header.map((item, index) => (
              <th
                key={index}
                className={`p-2 ${item.key && "hover:bg-neutral-50 cursor-pointer"}`}
                colSpan={item.colSpan || 1}
                onClick={() => {
                  item.key && onSort(item.key);
                }}>
                <div className={`flex items-center ${item.position === "right" ? "justify-end" : item.position === "center" ? "justify-center" : "justify-start"}`}>
                  <h3 className={`text-sm font-semibold ${item.position === "right" ? "text-right" : item.position === "center" ? "text-center" : "text-left"}`}>{item.title}</h3>
                  {item.key && (
                    <button className="flex flex-col justify-center">
                      {sort[item.key] === 1 ? <IoIosArrowUp /> : sort[item.key] === -1 ? <IoIosArrowDown /> : <IoIosArrowDown className=" opacity-0" />}
                    </button>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="relative">
          {loading ? (
            <tr>
              <td colSpan={header.reduce((acc, item) => acc + (item.colSpan || 1), 0)}>
                <div className="py-4 flex w-full justify-center bg-gray-50">
                  <Loader />
                </div>
              </td>
            </tr>
          ) : total === 0 ? (
            <tr>
              <td colSpan={header.reduce((acc, item) => acc + (item.colSpan || 1), 0)} className="py-4 text-center bg-gray-50">
                Aucune donnée trouvée
              </td>
            </tr>
          ) : (
            children
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
